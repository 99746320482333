<template>
    <transition appear enter-active-class="animated fadeIn">
        <div class="has-text-centered">
            <div class="level">
                <div class="title is-1 level-item page-title has-text-centered">Coming Soon ..... exciting new range of products!</div>
            </div>

            <div class="box b-shdw-3">
                <p>
                    We are currently upgrading our range of travel insurance policies and hope to be back on sale very soon.
                    <br /><br />
                    If you have an existing policy with us please be assured that this remains unaffected and remains in
                    force should you need to use it.
                    <br /><br />
                    If you are a new or returning customer and would like us to contact you when we resume selling
                    <strong>Covered2go Travel Insurance</strong> policies, please contact us at
                    <a href="mailto:enquiries@covered2go.co.uk">enquiries@covered2go.co.uk</a> or call us on
                    <strong>0333 400 7745</strong> and we will be in touch as soon as possible.
                    <br /><br />
                    We are very sorry for any inconvenience caused, however, we hope to be able to be back on sale very soon.
                    <br /><br />
                    <strong>The Covered2go Team</strong>
                </p>
            </div>

            <hr>

            <div class="box b-shdw-3 content">
                <p class="text-center">
                    <strong>Urgent Travel Insurance Enquiries</strong>
                </p>
                <p>
                    If your travel insurance needs are of an <strong>urgent</strong> nature, we have teamed up with travel
                    trade partners, <strong>Hays Travel</strong>, to assist you until we are able to resume
                    <strong>Covered2go</strong> sales.
                    <br /><br />
                    Please call our Customer Service Centre for a <strong>Hays Travel Insurance quote</strong> on
                    <strong>0333 400 7745</strong>.
                    <br /><br />
                    <strong>Hays Travel Insurance provides cover for:</strong>
                </p>
                <ul>
                    <li><p><i class="fas fa-check"></i> <span>Enhanced Covid-19 cover*</span></p></li>
                    <li><p><i class="fas fa-check"></i> European and worldwide cover available</p></li>
                    <li><p><i class="fas fa-check"></i> Single trip and annual multi-trip policies available</p></li>
                    <li><p><i class="fas fa-check"></i> Policy extensions available: Cruise, Winter Sports, Business, Golf, Wedding</p></li>
                    <li><p><i class="fas fa-check"></i> All pre-existing medical conditions considered</p></li>
                    <li><p><i class="fas fa-check"></i> Cancellation cover <span>(inclusive of cover for Covid-19)</span></p></li>
                    <li><p><i class="fas fa-check"></i> Medical expenses and repatriation included <span>(inclusive of cover for Covid-19)</span></p></li>
                    <li><p><i class="fas fa-check"></i> Personal possessions cover</p></li>
                    <li><p><i class="fas fa-check"></i> 24-hour medical assistance</p></li>
                    <li><p><i class="fas fa-check"></i> No upper age limit on Single Trip Policies</p></li>
                    <li><p><i class="fas fa-check"></i> FREE cover for kids under 18 on family policies</p></li>
                </ul>
                <p class="small">
                    <strong>Please Remember</strong> - There is no cover on any of our policies if you travel against
                    the Foreign, Commonwealth and Development Office (FCDO), government, local authority, or medical
                    advice.
                    <br /><br />
                    *Policy terms and conditions apply. For more information about cover, click here to view the
                    <a href="https://hay.isell.traveltek.net/images/www.haystravel.co.uk/insurancepdfs/HaysTravelPolicy202007.pdf" target="_blank" >Policy Wording</a>.
                </p>
                <p>
                    If we can be of any assistance, please do not hesitate to get in touch. Please call our Customer
                    Service Centre on <strong>0333 400 7745</strong>, alternatively, you can email us on
                    <a href="mailto:enquiries@covered2go.co.uk">enquiries@covered2go.co.uk</a>.
                    <br /><br />
                    <a href="https://www.haystravel.co.uk/" target="_blank">
                        <img src="https://www.rushportal.co.uk/hays/direct/_assets/hays_logo.png" alt="Hays Travel Logo" width="200px"/>
                    </a>
                    <br />
                </p>
                <p class="small">
                    For more details in relation to the full range of products and services available from Hays Travel
                    please click <a href="https://www.haystravel.co.uk/" target="_blank">here</a>.
                </p>
            </div>

            <!--<div class="box b-shdw-3">
                <p>Perhaps one of the links below might help you get where you want?:</p>
                <ul>
                    <li><router-link :to="{name:'home'}">Home</router-link></li>
                    <li><router-link :to="{name:'contactUs'}">Contact Us</router-link></li>
                    <li><router-link :to="{name:'faqs'}">FAQs</router-link></li>
                    <li><router-link :to="{name:'privacyPolicy'}">Privacy policy</router-link></li>
                    <li><router-link :to="{name:'cookiePolicy'}">Cookie policy</router-link></li>
                    <li><router-link :to="{name:'refundPolicy'}">Refund policy</router-link></li>
                </ul>
            </div>-->
        </div>
    </transition>
</template>

<script>
    export default {
        name: "Maintenance"
    }
</script>

<style lang="scss" scoped>
    ul {
        list-style-type: none;
    }

    strong {
        color: $c2g_orange;
    }

    span {
        color: green;
        font-weight: bold;
    }

    .fa-check {
        color: green !important;
    }

    .level-item {
        padding: 20px 0 20px 0;
    }
    .fas {
        color: $c2g_orange;
    }
</style>